<script>
import { roundTo } from '@shared/services/Utils';

export default {
  i18n: {
    messages: {
      fr: {
        affiliation_revenue: '€ générés par les affiliés',
        avg_customer_per_store: 'Apprenants / Store (en moyenne)',
        avg_deletion_delay_in_sec: 'Délai avant suppression de compte',
        avg_first_training_delay_in_sec: 'Délai avant 1ère formation créé',
        avg_pro_delay_in_sec: 'Délai avant de passer PRO',
        avg_stripe_connect_delay_in_sec: 'Délai avant de connecter Stripe',
        avg_training_item_per_training: 'Leçons / Formation (en moyenne)',
        customer_generated_revenue: '€ générés par les apprenants',
        nb_comments: 'Commentaires',
        nb_customers: 'Apprenants',
        nb_deleted_stores: 'Stores supprimés',
        nb_pro: 'Stores PRO',
        nb_pro_to_percent: 'Stores PRO -> DÉCOUVERTE',
        nb_stores: 'Stores',
        nb_stores_with_at_least_a_training: 'Stores avec 1 formation ou +',
        nb_stripe_connect: 'Stripe connect',
        nb_training_items: 'Leçons',
        nb_training_items_in_draft: 'Leçons en brouillon',
        nb_trainings: 'Formations',
        nb_trainings_in_draft: 'Formations en brouillon',
        nb_users: 'Utilisateurs',
        percentage_pro: '% de Stores PRO',
        percentage_stores_with_at_least_a_training: '% Stores avec 1 formation ou +',
        user_generated_revenue: '€ générés par les utilisateurs',
        store_name_with_top_trainings_count: 'Store avec le + de formations',
        store_name_with_top_training_items_count: 'Store avec le + de leçons',
        store_name_with_top_customers_count: 'Store avec le + d\'apprenants',
        store_name_with_top_customers_paid_price: 'Store avec la + grosse vente',
      },
    },
  },
  props: {
    statKey: {
      type: String,
      required: true,
    },
    statValue: {
      type: [String, Number],
      required: true,
    },
    statPreviousValue: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    getTimeFromSeconds(timeInSeconds) {
      const minuteInSeconds = 60;
      const hourInSeconds = minuteInSeconds * 60;
      const dayInSeconds = hourInSeconds * 24;
      const weeksInSeconds = dayInSeconds * 7;

      const weeks = Math.floor(timeInSeconds / weeksInSeconds);
      const days = Math.floor((timeInSeconds % weeksInSeconds) / dayInSeconds);
      const hours = Math.round(
        (((timeInSeconds % weeksInSeconds) % dayInSeconds) / hourInSeconds) * 100,
      );

      return { weeks, days, hours };
    },
  },
  computed: {
    value() {
      const { statValue } = this;

      if (this.statKey.endsWith('_in_sec')) {
        const { weeks, days, hours } = this.getTimeFromSeconds(statValue);

        return `${weeks ? `${weeks}s` : ''} ${days ? `${days}j` : ''} ${hours}h`.trim();
      }

      if (this.statKey.endsWith('_revenue')) {
        return `${this.statValue} €`;
      }

      return statValue;
    },
    growthValue() {
      if (this.statPreviousValue <= 0) {
        return 'N/A';
      }

      const growthPercent = ((this.statValue / this.statPreviousValue) - 1) * 100;

      return `${roundTo(growthPercent, 2)}%`;
    },
    previousValue() {
      const { statPreviousValue } = this;

      if (this.statKey.endsWith('_in_sec')) {
        const { weeks, days, hours } = this.getTimeFromSeconds(statPreviousValue);

        return `${weeks ? `${weeks}s` : ''} ${days ? `${days}j` : ''} ${hours}h`.trim();
      }

      if (this.statKey.endsWith('_revenue')) {
        return `${this.statPreviousValue} €`;
      }

      return statPreviousValue;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="
        box h-full has-text-centered is-flex is-flex-direction-column
        is-justify-content-space-between
      ">
      <h2 class="title is-5" v-t="statKey" />
      <p v-if="$slots.default" class="is-size-6 has-text-primary mb-5">
        <slot />
      </p>
      <p class="is-size-4 mb-5">
        {{ value }}
      </p>
      <p class="is-size-7">
        Période précédente : {{ previousValue }}
        <strong class="has-text-primary">
          <b-tooltip label="Croissance">
            ({{ growthValue }})
          </b-tooltip>
        </strong>
      </p>
    </div>
  </div>
</template>
