<script>
import APIAdmin from '@app/services/API/Admin';
import MetricsWrapper from '@app/components/MetricsWrapper.vue';
import AdminStat from '@app/components/AdminStat.vue';

export default {
  components: { MetricsWrapper, AdminStat },
  i18n: {
    messages: {
      fr: {
        revenus: 'Revenus',
        stores: 'Stores',
        trainings: 'Formations',
        customers: 'Apprenants',
      },
    },
  },
  data() {
    return {
      tops: {},
      form: { dates: [null, null] },
      topsList: [
        ['store_name_with_top_trainings_count', 'top_trainings_count'],
        ['store_name_with_top_training_items_count', 'top_training_items_count'],
        ['store_name_with_top_customers_count', 'top_customers_count'],
        ['store_name_with_top_customers_paid_price', 'top_customers_paid_price'],
      ],
      isFetching: false,
    };
  },
  watch: {
    'form.dates': {
      handler: 'fetchTops',
    },
  },
  methods: {
    fetchTops() {
      let [begin, end] = this.form.dates;

      begin = this.$moment(begin).format('YYYY-MM-DD');
      end = this.$moment(end).format('YYYY-MM-DD');

      this.isFetching = true;
      APIAdmin.getTops({ begin, end })
        .then(({ data }) => (this.tops = data))
        .finally(() => (this.isFetching = false));
    },
  },
};
</script>

<template>
  <MetricsWrapper v-model="form.dates" initialSelector="LAST_30_DAYS" :isLoading="isFetching">
    <template #title>
      Tops
    </template>
    <template #default>
      <b-skeleton v-if="isFetching" height="600" />
      <template v-else>
        <template v-if="tops.current">
          <div class="columns is-multiline">
            <AdminStat
              v-for="(list, listKey) in topsList" :key="listKey"
              class="column is-6"
              :statKey="list[0]"
              :statValue="tops.current[list[1]]"
              :statPreviousValue="tops.previous[list[1]]"
            >
              {{ tops.current[list[0]] }}
            </AdminStat>
          </div>
        </template>
      </template>
    </template>
  </MetricsWrapper>
</template>
