import { api } from '@shared/config/axios';

export default {
  getStats({ begin, end }) {
    return api.$get('/admin/stats', {
      params: { begin, end },
    });
  },
  getTops({ begin, end }) {
    return api.$get('/admin/tops', {
      params: { begin, end },
    });
  },
  updateEmail(data) {
    return api.$put('/admin/operations/emails', data);
  },
  disconnectStripe(data) {
    return api.$put('/admin/operations/stripe', data);
  },
};
